import React from "react"

export default function Footer() {
    return (
        <footer
            id="bgFooter"
            className="text-center text-lg-start text-white mt-5"
        >
            <section className="container pt-4 pb-4">
                <div className="row w-100 d-flex justify-content-center align-items-start">


                    <div className="col-md-4 d-flex mb-md-0 mb-4 justify-content-center align-items-center flex-column">
                        <h6 className="text-uppercase text-red fw-bold">Universidade do Vinho
                        </h6>
                        <div className="d-flex justify-content-start flex-column mt-2">
                            <p className="mb-2">
                                <i className="fas fa-home me-2"></i><span className="text-white">UDV consultoria Agro Ltda.
                                </span>
                            </p>
                            <p>
                                <i className="fas fa-print me-2"></i> <span className="text-white">23491835/0001-97
                                </span>
                            </p>
                        </div>

                    </div>

                    <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                        <h6 className="text-uppercase fw-bold  text-red">Redes Sociais</h6>
                        <div className="d-flex justify-content-start flex-column mt-2">
                            <a href="https://instagram.com/wine.anarella?igshid=YmMyMTA2M2Y="><p className="text-white mb-2"><i className="fa-brands fa-instagram me-2 text-white"></i>@wine.anarella</p></a>
                            <a href='mailto:contato@udvinho.com.br?subject=Quero saber mais sobre seu catálogo' className="mb-2 text-white"><i className="fas fa-envelope me-2"></i>contato@udvinho.com.br</a>
                            <a href="https://api.whatsapp.com/send?phone=5562999715407&text=Olá, gostaria de saber mais sobre o seu catálogo!"><p className="text-white mb-2"><i className="fa-brands fa-whatsapp me-2 text-white"></i>(62) 99971-5407</p></a>
                        </div>

                    </div>

                    <div className="col-md-4 d-flex mb-md-0 mb-5 justify-content-center d-flex align-items-center">
                        <img className="img-fluid" width={150} src="https://fada-wine.s3.amazonaws.com/logo-fada.svg" alt="logo.svg" />
                    </div>
                </div>
            </section>

        </footer>
    )
}
