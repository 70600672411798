import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Main } from "./Main";
import React from "react";

export const Admin = () => {
    return (
        <div className="bg-dark h-100vh">
            <Main />
        </div>
    )
} 