import React from "react";

export default function Header() {
    return (
        <>
            <section className="pt-5 pb-5 bg-header text-white">
                <div className="container d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
                    <p className="fs-2 mt-5 pt-5 pb-5 text-center fw-light fst-italic"></p>
                </div>
                <div className=" container row">
                    <div className="col-sm fs-2 mt-5 pt-5 flex-row">
                        <p className="ms-5">Felicidade Engarrafada</p>
                    </div>
                </div>
            </section>

            <div className="bg-gold d-flex justify-content-center text-white">
                <div><p><br /></p></div>
            </div>
        </>
    )
}
