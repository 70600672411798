import React from "react";
import { useNavigate } from "react-router-dom";

export const Cards: React.FC<any> = ({ older }) => {

    const navigate = useNavigate();

    function redirectTo(category: string) {
        navigate('catalogo', {
            state: {
                filter: category
            }
        })
    }

    return (
        

        <section id="card-group" className="container-fluid" style={{ pointerEvents: older ? 'auto' : 'none' }}>
            <h2 id="headerMenu" className="text-center text-red position-relative ">CATEGORIAS</h2>
            <p className="text-center pb-4 fst-italic">Escolha o fltro que desejar no nosso Catálogo</p>
            <div className="row card-group">
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0 " onClick={e => redirectTo('tinto')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-tinto.svg" alt="fundo-card" />
                    </button>
                </div>
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0" onClick={e => redirectTo('branco')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-branco.svg" alt="fundo-card" />
                    </button>
                </div>
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0" onClick={e => redirectTo('rose')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-rose.svg" alt="fundo-card" />
                    </button>
                </div>
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0" onClick={e => redirectTo('destilado')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-destilado.svg" alt="fundo-card" />
                    </button>
                </div>
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0" onClick={e => redirectTo('fortificado')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-fortificados.svg" alt="fundo-card" />
                    </button>
                </div>
                <div className="col-md-4 mt-4 d-flex justify-content-center">
                    <button className="btn btn-default p-0" onClick={e => redirectTo('champagnes & espumantes')}>
                        <img className="img-fluid rounded" src="https://fada-wine.s3.amazonaws.com/card-espumantes.svg" alt="fundo-card" />
                    </button>
                </div>
            </div>
        </section>
    );
}
